import React from 'react'
import { Navigation } from '../../../common/navigation'
import { GlobalHelmet } from '../../../common/seo/globalHelmet'
import { MatchingNavigation } from '../../../common/matching-navigation'
import { MatchingQuestion } from '../../../common/matching-question'
import { MatchingAnswers } from '../../../common/matching-answers'
import { AREA_CARE_PATH } from '../../../config/constants'

export default function Frage9() {
  const question = {
    title: 'Frage 9 von 35',
    headline: '',
    subline: 'Palliativpflege ...',
  }

  const answers = [
    {
      name: 'Palliativpflege',
      id: 'gern',
      value: '... ist ein wichtiger Teil, den ich gern übernehme.',
      desc: '',
    },
    {
      name: 'Palliativpflege',
      id: 'nicht-so',
      value: '... ist nicht so meine Sache – möchte ich nicht ständig machen.',
      desc: '',
    },
    {
      name: 'Palliativpflege',
      id: 'nope',
      value: '... möchte ich eher nicht machen.',
      desc: '',
    },
  ]

  return (
    <>
      <GlobalHelmet title={`UKE jukebox - ${question.title}`} />
      <main className="bg-img-turkis-waves">
        <Navigation />

        <section className="quiz">
          <div className="row text-center">
            <MatchingQuestion
              color="turkis"
              headline={question.headline}
              subline={question.subline}
            />
            <MatchingAnswers
              type="single"
              answers={answers}
              color="yellow"
              index={9}
            />
          </div>
        </section>

        <MatchingNavigation
          color="turkis"
          current={question.title}
          next={`${AREA_CARE_PATH}/spielen/gewichtung-1`}
          prev={`${AREA_CARE_PATH}/spielen/frage-8`}
          index={9}
        />
      </main>
    </>
  )
}
